import React from 'react';
import { Redirect } from 'react-router-dom';

const LoadingWrapper = ({ loading, loadingText, isEmpty, children }) => {
  
  if (loading) {
    return (
      <div className="is-flex is-flex-direction-column mt-6 mb-6 is-align-items-center is-justify-content-center">
        <div className="mb-4">{loadingText || 'Loading, please wait...'}</div>
        <progress class="progress is-primary is-large" max="100" />
      </div>
    )
  }

  if (isEmpty) {
    return <Redirect to="/404" />
  }

  return children;
}

export default LoadingWrapper;