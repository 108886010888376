import { gql } from '@apollo/client';

export const productInfoFragment = gql`
  fragment productInfo on Product {
    id
    name
    quota
    time
    currency
    price
  }
`;

export const orderFragment = gql`
  fragment order on Order {
    id
    guid
    name
    email
    phone
    qty
    totalprice
    product {
      ...productInfo
    }
  }
  ${productInfoFragment}
`;

export const getOrderByIdQuery = gql`
  query ($orderId: ID!) {
    order(id: $orderId) {
      ...order
    }
  }
  ${orderFragment}
`
export const getOrderByGuidQuery = gql`
  query ($orderId: ID!, $guid: String!) {
    orderByGuid(id: $orderId, guid: $guid) {
      ...order
      payment {
        id
      }
    }
  }
  ${orderFragment}
`

export const submitOrderMutation = gql`
  mutation (
    $name: String!,
    $email: String!,
    $phone: String!,
    $product: ID!,
    $qty: Int!,
    $otherdetails: JSON,
    $totalprice: Int!
  ){
    createOrder(
      input: {
        data: {
          name: $name
          email: $email
          phone: $phone
          product: $product
          qty: $qty
          otherdetails: $otherdetails
          totalprice: $totalprice
        }
      }
    ) {
      order {
        ...order
      }
    }
  }
  ${orderFragment}
`