import React from 'react';
import { useField } from 'formik';
import { mdiCheckboxBlankOutline, mdiCheckboxMarked, mdiRadioboxBlank, mdiRadioboxMarked } from '@mdi/js';
import Icon from '@mdi/react';

const InputField = ({ name, label, options, required, hint, inputType = 'shorttext', leftIcon, rightIcon, leftAddons, rightAddons, ...otherProps }) => {
  const [field, meta, helpers] = useField(name)

  const { error, touched, value } = meta;
  const { setValue, setTouched } = helpers;

  let input;
  let rightIconView;
  let leftAddonsView;
  let rightAddonsView;
  let horizontal = false;

  const hasError = error && touched;

  const onOptionClick = (inputValue) => () => {
    setValue(inputValue);
  }

  switch (inputType) {
    case 'yesno': {
      horizontal = true;
      input = (
        <div className="buttons has-addons">
          <button type="button" onClick={onOptionClick(true)} className={`button ${value === true ? 'is-selected is-success ' : ''}`}>YES</button>
          <button type="button" onClick={onOptionClick(false)} className={`button ${value === false ? 'is-selected is-danger ' : ''}`}>NO</button>
        </div>
      )
      break;
    }

    case 'radio': {
      input = (options && options.length > 0) ? options.map(
        ({ id, name }) => {
          const selected = value === id;
          return (
            <button type="button" onClick={onOptionClick(id)} className={`button mb-2 is-justify-content-flex-start is-fullwidth ${selected ? 'is-primary ' : ''}`}>
              <span className="icon ml-0 mr-2">
                <Icon className={selected ? 'is-white' : ''} path={selected ? mdiRadioboxMarked : mdiRadioboxBlank} />
              </span>
              <span>{name}</span>
            </button>
          )
        }
      ) : null;
      break;
    }

    case 'checkboxes': {
      const onCheckboxClick = (inputValue) => () => {
        const newValue = (value && value.includes(inputValue)) ? value.filter(v => v !== inputValue) : [...(value || []), inputValue];
        setValue(newValue);
      }
      input = (options && options.length > 0) ? options.map(
        ({ id, name }) => {
          const selected = value && value.includes(id);
          return (
            <button type="button" onClick={onCheckboxClick(id)} className={`button mb-2 is-justify-content-flex-start is-fullwidth ${selected ? 'is-primary ' : ''}`}>
              <span className="icon ml-1 mr-2">
                <Icon className={selected ? 'is-white' : ''} path={selected ? mdiCheckboxMarked : mdiCheckboxBlankOutline} />
              </span>
              <span>{name}</span>
            </button>
          )
        }
      ) : null;
      break;
    }

    case 'longtext': {
      input = (
        <div className="control">
          <textarea className={`textarea ${hasError ? 'is-danger ' : ''}`} name={name} {...field} {...otherProps} />
        </div>
      )
      break;
    }

    case 'number': {
      horizontal = true;
      input = (
        <div className="control">
          <button class="button is-static"><b>{value}</b></button>
        </div>
      )

      leftAddonsView = (
        <div class="control">
          <button type="button" disabled={Number(value) <= 1} onClick={onOptionClick(Number(value) - 1)} class="button is-primary">-</button>
        </div>
      )
      rightAddonsView = (
        <div class="control">
          <button type="button" class="button is-primary" onClick={onOptionClick(Number(value) + 1)}>+</button>
        </div>
      )
      break;
    }

    default: {
      input = (
        <div className={`control ${leftIcon ? 'has-icons-left ' : ''}${rightIconView ? 'has-icons-right' : ''}`}>
          <input className={`input ${hasError ? 'is-danger ' : ''}`} name={name} {...field} {...otherProps} />
          {leftIcon && (<span class="icon is-small is-left">{leftIcon}</span>)}
          {rightIcon && (<span class="icon is-small is-right">{rightIcon}</span>)}
        </div>
      )

      if (leftAddons) {
        leftAddonsView = (<div class="control"><button class="button is-static">{leftAddons}</button></div>)
      }

      if (rightAddons) {
        rightAddonsView = (<div class="control"><button class="button is-static">{rightAddons}</button></div>)
      }

      break;
    }
  }

  if (horizontal) {
    return (
      <div className="field is-horizontal mb-5 mt-4">
        <div className="field-label">
          <label class="label has-text-left">{label}</label>
        </div>
        <div className="field-body is-flex-direction-column">
          <div className={`field mr-0 is-narrow ${(leftAddonsView || rightAddonsView) ? 'has-addons' : ''}`}>
            {leftAddonsView}
            {input}
            {rightAddonsView}
          </div>
          {hasError && <p className="help is-danger has-text-left">{error}</p>}
        </div>
      </div>
    )
  }

  let helpText;
  if (hasError) {
    helpText = <p className="help is-danger">{error}</p>
  } else if (hint) {
    helpText = <p className="help">{hint}</p>
  }

  return (
    <div class={`field has-text-left mb-5 mt-4 ${(leftAddonsView || rightAddonsView) ? 'has-addons' : ''}`}>
      <label class="label">{label}</label>
      {leftAddonsView}
      {input}
      {rightAddonsView}
      {helpText}
    </div>
  )
}

export default InputField;