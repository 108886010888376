import React from 'react';
import moment from 'moment';

const ProductSummary = ({ name, time, image, currency, price }) => {
  const momentDate = moment(time);
  const imageUrl = (image && image.url) ? `${process.env.REACT_APP_SERVER_URL}${image.url}` : 'https://via.placeholder.com/150';

  return (
    <div className="card">
      <div className="card-image is-hidden-mobile">
        <figure className="image is-4by3">
          <img src={imageUrl} alt={name} />
        </figure>
      </div>
      <div className="card-content has-text-left">
        <h6 className="subtitle is-6">You are purchasing</h6>
        <h5 className="title is-5">{name}</h5>
        <div>
          {momentDate.isValid() ? <>{momentDate.format('ddd, DD MMM YYYY HH:mm')}<br /></> : ''}
          {currency} {price}
        </div>
      </div>
    </div>
  )
}

export default ProductSummary;