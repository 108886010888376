import React, { useEffect, useState } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { ApolloProvider, ApolloClient, InMemoryCache } from '@apollo/client';
import { persistCache, LocalStorageWrapper } from 'apollo3-cache-persist';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';

import Order from './containers/Order';
import Payment from './containers/Payment';
import Page404 from './containers/Page404';

import './App.sass';
import './App.css';

function App() {
  const [client, setClient] = useState(null);

  useEffect(() => {
    const initClient = async () => {
      const cache = new InMemoryCache();

      await persistCache({
        cache,
        storage: new LocalStorageWrapper(window.localStorage),
      });

      const generatedClient = new ApolloClient({
        cache,
        uri: `${process.env.REACT_APP_SERVER_URL}/graphql`
      });

      setClient(generatedClient);
    }

    initClient();
  }, [])

  const navbar = (
    <nav className="navbar is-light is-fixed-top" role="navigation" aria-label="main navigation">
      <div className="container is-max-desktop">
        <div className="navbar-start">
          <div className="navbar-item">Indonesian Tutors</div>
        </div>
      </div>
    </nav>
  )

  if (!client) {
    return (
      <div className="App">
        {navbar}
      </div>
    )
  }

  return (
    <BrowserRouter>
      <ApolloProvider client={client}>
        <PayPalScriptProvider options={{ "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID }}>
          <div className="App">
            {navbar}
            <div className="container p-4 is-max-desktop">
              <Switch>
                <Route path="/404" component={Page404} />
                <Route path="/order" exact component={Order} />
                <Route path="/payment" exact component={Payment} />
                <Redirect from='*' to='/404' />
              </Switch>
            </div>
          </div>
        </PayPalScriptProvider>
      </ApolloProvider>
    </BrowserRouter>
  );
}

export default App;
