import { gql } from '@apollo/client';

export const additionalQuestionFragment = gql`
  fragment additionalQuestion on AdditionalQuestion {
    id
    label
    key
    type
    required
    options
    placeholder
    hint
  }
`;

export const productFragment = gql`
  fragment product on Product {
    id
    name
    image {
      url
    }
    description
    quota
    time
    openat
    closeat
    currency
    price
    additional_questions {
      ...additionalQuestion
    }
  }
  ${additionalQuestionFragment}
`;

export const getProductByIdQuery = gql`
  query ($productId: ID!) {
    product(id: $productId) {
      ...product
    }
  }
  ${productFragment}
`

export const getProductBySlugQuery = gql`
  query ($slug: String) {
    productBySlug(slug: $slug) {
      ...product
    }
  }
  ${productFragment}
`