import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js';
import parsePhoneNumber from 'libphonenumber-js';

import Icon from '@mdi/react';
import { mdiCheckCircle } from '@mdi/js';

import { useQueryParams } from '../hooks/query-params';
import { getOrderByGuidQuery } from '../graphql/order';
import LoadingWrapper from '../components/LoadingWrapper';
import { addPaymentMutation } from '../graphql/payment';

const Payment = () => {
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [paymentError, setPaymentError] = useState('');
  const [finalizingPayment, setFinalizingPayment] = useState(false);

  const { orderid: orderId, guid } = useQueryParams();

  const [{ isPending }] = usePayPalScriptReducer();

  const {
    loading: loadingOrder,
    data: { orderByGuid: order } = {},
    error: errorLoadingOrder
  } = useQuery(getOrderByGuidQuery, { variables: { orderId, guid }, fetchPolicy: "cache-and-network" });

  const [
    addPayment
  ] = useMutation(addPaymentMutation, {
    onCompleted: () => {
      setFinalizingPayment(false);
      setPaymentSuccess(true);
    },
    onError: ({ message }) => {
      setFinalizingPayment(false);
      setPaymentError(message)
    }
  })

  const hasOrder = order && order.id && !errorLoadingOrder;

  if (!loadingOrder && !hasOrder) {
    return <Redirect to="/404" />
  }

  const { product, email, name, phone, totalprice, payment } = order || {};
  const { name: productName, currency } = product || {};


  if (payment || paymentSuccess) {
    return (
      <section>
        <div className="card mt-4">
          <div className="card-content">
            <h5 className="title is-5">Payment success!</h5>
            <Icon
              path={mdiCheckCircle}
              size="150px"
              color="green"
            />
            <h4 className="title is-4">{currency} {totalprice}</h4>
            <h5 className="subtitle is-5">for {productName}</h5>
            <p>
              Please check your email for further information.
            </p>
          </div>
        </div>
      </section>
    )
  }

  const onCreateOrder = (data, actions) => {
    const [given_name, ...otherNames] = name.split(' ');
    const surname = otherNames.length > 0 ? otherNames.join(' ') : given_name;
    const { country, nationalNumber } = parsePhoneNumber(phone);

    return actions.order.create({
      payer: {
        name: { given_name, surname },
        email_address: email,
        phone: {
          phone_type: 'MOBILE',
          phone_number: {
            national_number: nationalNumber
          }
        },
        address: {
          country_code: country
        }
      },
      purchase_units: [
        {
          amount: {
            value: totalprice,
          },
        },
      ],
      application_context: {
        shipping_preference: 'NO_SHIPPING'
      }
    })
  }

  const onPaymentSuccess = async (data, actions) => {
    try {
      setFinalizingPayment(true);
      await actions.order.capture();
      addPayment({
        variables: {
          paymentid: data.orderID,
          order: orderId,
          channel: 'paypal'
        }
      })
    } catch (e) {
      setPaymentError(e)
    }
  }

  const onPaymentError = (error) => {
    setPaymentError(error.message)
  }

  return (
    <LoadingWrapper
      loading={loadingOrder || isPending}
      loadingText="Loading payment info, please wait..."
    >
      <section>
        <div class="hero-body pt-3 pb-3">
          <div class="container">
            <h6 class="subtitle is-6">Finish your payment for</h6>
            <h3 class="title">{productName}</h3>
            <h5 class="subtitle">({currency} {totalprice})</h5>
          </div>
        </div>
        {paymentError && (
          <div class="notification is-danger">
            {paymentError}
          </div>
        )}
        <div style={{ position: 'relative', maxWidth: 500, margin: '0 auto' }} className="pt-5 pb-5">
          <PayPalButtons
            style={{ layout: "vertical" }}
            createOrder={onCreateOrder}
            onApprove={onPaymentSuccess}
            onError={onPaymentError}
          />
          <div style={{ position: 'absolute', zIndex: 9999 }} class={`modal ${finalizingPayment ? 'is-active' : ''}`}>
            <div class="modal-background" style={{ backgroundColor: 'rgba(255, 255, 255, 0.9)' }} />
            <div class="modal-content pl-6 pr-6" style={{ width: '100%' }}>
              <b>Finalizing your payment, please wait...</b>
              <progress class="progress is-primary is-small mt-2" max="100" />
            </div>
          </div>
        </div>
      </section>
    </LoadingWrapper>
  )
}

export default Payment;