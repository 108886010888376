import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';

import { useQueryParams } from '../hooks/query-params';
import { getProductByIdQuery } from '../graphql/product';
import { submitOrderMutation } from '../graphql/order';
import LoadingWrapper from '../components/LoadingWrapper';
import OrderForm from '../components/OrderForm';
import ProductSummary from '../components/ProductSummary';
import OrderSummary from '../components/OrderSummary';

const Order = () => {
  const [order, setOrder] = useState({});
  const [submissionError, setSubmissionError] = useState('');
  const [step, setStep] = useState(1);

  const { productid: productId } = useQueryParams();

  const {
    loading: loadingProduct,
    data: { product } = {},
    error: errorLoadingProduct
  } = useQuery(getProductByIdQuery, { variables: { productId }, fetchPolicy: "cache-and-network" });

  const [
    submitOrder,
    { loading: submittingOrder }
  ] = useMutation(submitOrderMutation, {
    onCompleted: ({ createOrder } = {}) => {
      const { order: submittedOrder } = createOrder || {};

      if (submittedOrder && submittedOrder.id && submittedOrder.guid) {
        setOrder({
          ...order,
          id: submittedOrder.id,
          guid: submittedOrder.guid
        })
      } else {
        setSubmissionError('An error occured, please try again');
      }
    },
    onError: ({ message }) => {
      const emailOrPhoneUsed = (
        message && (message.endsWith('UNIQUE constraint failed: orders.emailphonekey'))
      );
      setSubmissionError(
        emailOrPhoneUsed
        ? 'Your email and/or phone are already used for ordering the same product. Please use different email and/or phone number.'
        : 'An error occured, please try again'
      )
    }
  })

  if (order && order.id && order.guid) {
    return <Redirect to={`/payment?orderid=${order.id}&guid=${order.guid}`} />;
  }

  if (!loadingProduct && errorLoadingProduct) {
    return <Redirect to="/404" />
  }

  const { id, currency, price, additional_questions } = product || {};

  const onSaveOrder = (values) => {
    const { qty } = values;
    setOrder({
      ...order,
      ...values,
      product: Number(id),
      totalprice: (price * qty)
    });
    setStep(step + 1);
  };

  const onSubmitOrder = () => {
    setSubmissionError('');
    submitOrder({
      variables: {
        ...order
      }
    });
  }

  const onEditInfo = () => {
    setSubmissionError('');
    setStep(step - 1);
  }

  return (
    <section>
      <LoadingWrapper
        loading={loadingProduct}
        loadingText="Loading product info, please wait..."
      >
        {step === 1 && (
          <div className="columns">
            <div className="column"><ProductSummary {...product} /></div>
            <div className="column is-two-thirds">
              <OrderForm
                order={order}
                onSaveOrderData={onSaveOrder}
                additionalQuestions={additional_questions}
                currency={currency}
                itemPrice={price}
              />
            </div>
          </div>
        )}
        {step === 2 && (
          <>
            {submissionError && (
              <div class="notification is-danger">
                {submissionError}
              </div>
            )}
            <OrderSummary
              product={product}
              order={order}
              submitting={submittingOrder}
              onSubmit={onSubmitOrder}
              onGoBack={onEditInfo}
            />
          </>
        )}
      </LoadingWrapper>
    </section>
  )

}

export default Order;