import { useLocation } from 'react-router-dom';

export const useQueryParams = () => {
  const { search } = useLocation();
  const params = {};

  if (search) {
    const searchParams = new URLSearchParams(search);
    for (var key of searchParams.keys()) {
      params[key] = searchParams.get(key)
    }
  }

  return params;
}