import React from 'react';
import { withFormik, Form } from 'formik';
import * as Yup from 'yup';
import 'yup-phone';

import InputField from './InputField';

const OrderForm = ({ values, currency, itemPrice = 0, additionalQuestions }) => {
  return (
    <Form>
      <h5 className="subtitle has-text-left">Fill your details below</h5>
      <InputField name="name" label="Your Full Name" placeholder="Firstname Lastname" required type="text" />
      <InputField name="email" label="Your Email Address" placeholder="some@email.com" required type="email" />
      <InputField
        name="phone"
        label="Your Phone Number"
        placeholder="+62xxxxxxxxxx"
        required
        type="text"
        hint="Make sure you have active WhatsApp on this number"
      />
      <div className="is-flex is-align-items-flex-start is-justify-content-flex-start">
        <InputField name="qty" required label="Qty." inputType="number" />
        <div className="pt-2 pl-5 has-text-left">
          <small>You will pay</small><br /><h5 class="title is-5">{currency} {itemPrice * values.qty}</h5></div>
      </div>
      {(additionalQuestions && additionalQuestions.length > 0) && additionalQuestions.map(
        ({ key, label, type, options, required }) => 
        <InputField name={key} required={required} label={label} inputType={type} options={options} />
      )}
      <button className="button is-primary" type="submit">NEXT</button>
    </Form>
  )
}

const generateValidator = (type, required) => {
  let validator;
  if (type === 'yesno') {
    validator = Yup.boolean();
  } else if (type === 'checkboxes') {
    validator = Yup.array();
    if (required) {
      validator.min(1, 'You must select at least one option')
    }
  } else if (type === 'number') {
    validator = Yup.number().integer('Invalid number')
  } else {
    validator = Yup.string();
  }

  if (required) {
    validator = validator.required('This field is required')
  }

  return validator;
}

const generateValidatorSchema = (additionalQuestions) => {
  const schema = {
    name: Yup.string()
      .required('You must fill your full name'),
    email: Yup.string()
      .email('Invalid email format')
      .required('You must fill your email address'),
    phone: Yup.string()
      .phone('ID', false, 'Invalid phone number')
      .matches(/\+([0-9]*)/g, { message: 'Invalid phone number', excludeEmptyString: true })
      .required('You must fill your phone number'),
    qty: Yup.number()
      .integer('Invalid number')
      .min(1, 'You must at least buy one item')
      .required('You must fill the qty.')
  }

  return (additionalQuestions && additionalQuestions.length > 0) ? (
    additionalQuestions.reduce(
      (prevSchema, { key, type, required }) => ({ ...prevSchema, [key]: generateValidator(type, required) }),
      schema
    )
  ) : schema;
}

export default withFormik({
  mapPropsToValues: ({
    additionalQuestions,
    order: { name, email, phone, qty = 1, otherdetails = {} }
  }) => {
    const additionalAnswers = (additionalQuestions && additionalQuestions.length > 0) ?
      additionalQuestions.reduce((qna, { key }) => {
        return {
          ...qna,
          [key]: otherdetails[key]
        }
      }, {}) : {};
    return { name, email, phone, qty, ...additionalAnswers }
  },
  enableReinitialize: true,
  validationSchema: ({ additionalQuestions }) => {
    const schema = generateValidatorSchema(additionalQuestions);
    return Yup.object().shape(schema);
  },
  handleSubmit: (values, { props }) => {
    const { name, email, phone, qty, ...otherdetails } = values;
    props.onSaveOrderData({ name, email, phone, qty, otherdetails });
  }
})(OrderForm);