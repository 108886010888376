import React from 'react';

const OrderSummary = ({ order, product, onSubmit, submitting, onGoBack }) => {
  const { name, email, phone, otherdetails, qty, totalprice } = order;
  const { name: productName, currency, price, additional_questions } = product

  return (
    <div>
      <h5 className="title is-5">Check Again Your Info</h5>
      <div className="box">
        <h6 className="subtitle is-6">PERSONAL INFO</h6>
        <table className="table is-striped is-narrow">
          <tr>
            <td className="has-text-left pr-4"><b>Name</b></td>
            <td className="has-text-left">{name}</td>
          </tr>
          <tr>
            <td className="has-text-left pr-4"><b>Email Address</b></td>
            <td className="has-text-left">{email}</td>
          </tr>
          <tr>
            <td className="has-text-left pr-4"><b>Phone Number</b></td>
            <td className="has-text-left">{phone}</td>
          </tr>
        </table>
      </div>
      <div className="box">
        <h6 className="subtitle is-6">ORDER SUMMARY</h6>
        <table className="table is-striped is-narrow">
          <tr>
            <td className="has-text-left pr-4">
              {productName}<br />
              {qty}x {currency} {price}
            </td>
            <td className="has-text-left"><br /><b>{currency} {totalprice}</b></td>
          </tr>
        </table>
      </div>
      {(additional_questions && additional_questions.length > 0) && (
        <div className="box">
          <h6 className="subtitle is-6">ADDITIONAL INFO</h6>
          <table className="table is-striped is-narrow">
            {additional_questions.map((question) => {
              const { key, label, type, options } = question;
              const answer = otherdetails[key];
              const getAnswerLabel = (a) => (options.find(option => option.id === a) || {}).name;
              let answerText;
              
              if (type === 'checkboxes') {
                answerText = Array.isArray(answer) ? answer.map(getAnswerLabel).join(', ') : '';
              }  else if (type === 'radio') {
                answerText = getAnswerLabel(answer);
              } else if (type === 'yesno') {
                answerText = answer === true ? 'Yes' : 'No'
              } else {
                answerText = answer;
              }

              return (
                <tr>
                  <td className="has-text-left pr-4"><b>{label}</b></td>
                  <td className="has-text-left">{answerText}</td>
                </tr>
              )
            })}
          </table>
        </div>
      )}
      <button className={`button is-fullwidth mb-2 is-primary ${submitting ? 'is-loading' : ''}`} onClick={onSubmit}>
        PROCEED TO PAYMENT
      </button>
      <button className="button is-fullwidth" onClick={onGoBack}>
        EDIT YOUR DETAILS
      </button>
    </div>
  )
}

export default OrderSummary;