import { gql } from '@apollo/client';

export const paymentFragment = gql`
  fragment payment on Payment {
    id
    paymentid
    channel
    order {
      id
    }
  }
`;

export const addPaymentMutation = gql`
  mutation (
    $paymentid: String!,
    $order: ID!,
    $channel: ENUM_PAYMENT_CHANNEL!
  ) {
    createPayment(
      input: {
        data: {
          paymentid: $paymentid
          order: $order
          channel: $channel
        }
      }
    ) {
      payment {
        ...payment
      }
    }
  }
  ${paymentFragment}
`;